<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A sample of a gas is in a reaction vessel under a certain set of conditions such that it
        possesses
        <number-value :value="microstates1" /> microstates. The volume of the vessel is then changed
        leading to an increase in the number of microstates by a factor of
        <number-value :value="microstateIncrease" />.
      </p>

      <p class="mb-2">a) Determine the entropy for the final configuration of the gas.</p>

      <calculation-input
        v-model="inputs.Sf"
        class="mb-5"
        prepend-text="$\text{S}:$"
        append-text="$\text{J/K}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the change in entropy when the sample goes from the initial state to the final
        state.
      </p>

      <calculation-input
        v-model="inputs.deltaS"
        class="mb-2"
        prepend-text="$\Delta\text{S}:$"
        append-text="$\text{J/K}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question299',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Sf: null,
        deltaS: null,
      },
    };
  },
  computed: {
    microstates1() {
      return this.taskState.getValueBySymbol('microstates1').content;
    },
    microstateIncrease() {
      return this.taskState.getValueBySymbol('microstateIncrease').content;
    },
  },
};
</script>
